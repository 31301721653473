.treeRoot {
	height: 100%;
}

.draggingSource {
	opacity: 0.3;
}

.dropTarget {
	background-color: #e8f0fe;
}
