.root {
	align-items: center;
	display: flex;
	height: 32px;
	padding-inline-end: 8px;
	width: 750px;
}

.expandIconWrapper {
	align-items: center;
	font-size: 0;
	cursor: pointer;
	display: flex;
	height: 24px;
	justify-content: center;
	width: 24px;
	transition: transform linear 0.1s;
	transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
	transform: rotate(90deg);
}
